.diagram {
  box-sizing: border-box;
  padding: 12px;
  width: 500px;
  height: 400px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

}
.diagram:not(:first-child) {
  margin: 200px 0;
}

.column {
  width: 66px;
  background-color: royalblue;
}
h1 {
  margin-bottom: 100px;
}